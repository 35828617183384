<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Organization overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center" v-if="organization">{{ organization.name }}</h1>
                <!-- <p class="text-caption text-center">DNS</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="organization">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-app-bar color="blue darken-2" dark flat dense>
                            <v-app-bar-title>Organization overview</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-0">Products</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-product', params: { organizationId: this.$route.params.organizationId } }">See all products</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.product_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-0">Accounts</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-account', params: { organizationId: this.$route.params.organizationId } }">See all accounts</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.account_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-0">Payments</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-payment', params: { organizationId: this.$route.params.organizationId } }">See all payments</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.payment_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-0">Refunds</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-refund', params: { organizationId: this.$route.params.organizationId } }">See all refunds</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.refund_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-0">Invoices</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-invoice', params: { organizationId: this.$route.params.organizationId } }">See all invoices</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.invoice_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-8">Users</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-user', params: { organizationId: this.$route.params.organizationId } }">See all users</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.user_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-8">API Tokens</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-client-token', params: { organizationId: this.$route.params.organizationId } }">See all API tokens</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.client_token_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-8">Stripe</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-stripe', params: { accountId: this.$route.params.accountId } }">Manage Stripe Settings</router-link>
                            </p>

                            <!-- <template v-if="organizationSummary && (organizationSummary.domain_dispute_claimant_count > 0 || organizationSummary.domain_dispute_defendant_count > 0)">
                            <p class="text-overline mb-0 mt-8">Disputes</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-domain-dispute', params: { organizationId: this.$route.params.organizationId }, query: { role: 'claimant' } }">See all disputes for a domain name</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.domain_dispute_claimant_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-search-domain-dispute', params: { organizationId: this.$route.params.organizationId }, query: { role: 'defendant' } }">See all disputes against you</router-link>
                                <span v-if="organizationSummary" class="ml-2">({{ organizationSummary.domain_dispute_defendant_count }})</span>
                            </p>
                            </template> -->

                            <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-8">BrandProfile (Service Administrator ONLY) <font-awesome-icon :icon="['fas', 'id-badge']" class="blue--text text--darken-2"/></p>
                            <p class="mb-0 pb-0">
                                You can link your organization with a BrandProfile to show your logo and custom brand color palette on your customer service website.
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-brandprofile', params: { accountId: this.$route.params.accountId } }">Manage BrandProfile</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-edit-hostname', params: { accountId: this.$route.params.accountId } }">Custom Hostname</router-link>
                            </p>
                            </template>

                            <p class="text-overline mb-0 mt-8">Customer Website</p>
                            <p class="mb-0 pb-0" v-if="organizationSummary.customer_website_url">
                                <a :href="organizationSummary.customer_website_url" target="_blank">Customer Website URL</a>
                            </p>
                            <p class="mb-0 pb-0" v-if="!organizationSummary.customer_website_url">
                                Your customer website URL is not configured. Set up a BrandProfile to create your customer website.
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Tools</p> -->
                            <!-- <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-view-nameservers', params: { organizationId: this.$route.params.organizationId } }">Check nameservers for a domain</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-view-whois', params: { organizationId: this.$route.params.organizationId } }">Check whois for a domain</router-link>
                            </p> -->
                            <!-- <OrganizationViewList :list="list"/> -->
                            <!-- <RealmList @selected="onSelectRealm" create class="mt-12"/> -->
                            <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'organization-delete', params: { organizationId: this.$route.params.organizationId } }">How to delete this organization</router-link>
                            </p>

                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="blue--text"/></p>
                            <p class="mb-0 pb-0">
                            </p>
                            </template> -->

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import UserList from '@/components/organization-dashboard/UserList.vue';

export default {
    components: {
        // UserList,
    },
    data: () => ({
        organization: null,
        organizationSummary: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        name() {
            return this.organization?.name ?? 'Unknown';
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    watch: {
        focus() {
            this.loadOrganization();
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                    this.loadOrganizationSummary();
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadOrganizationSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganizationSummary: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.check({ item: 'summary' });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organizationSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"domain_dispute_defendant":0,"domain_dispute_claimant":0,"dns_record_count":4}
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganizationSummary: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
    },
};
</script>
